var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({ whiteSpace: 'nowrap' })},[_c('div',{staticClass:"designtool-read"},[_c('div',[_c('el-card',{staticClass:"box-card",style:({ background: '#666A86', color: '#fff' })},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Read Command")])]),_c('div',{staticClass:"text item"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c('DesignToolLogic-Edit',{attrs:{"id":_vm.id,"editLogic":_vm.editLogic}}),_c('DesignToolLogic-Delete',{attrs:{"id":_vm.id,"deleteLogic":_vm.deleteLogic}})],1)]),_c('div',{staticClass:"designtool-arrow-right"},[_c('DesignToolLogic-Arrow-Right')],1),_c('div',{staticClass:"designtool-read",style:({ marginRight: '10px' })},[_c('el-card',{staticClass:"box-card",style:({ background: '#97D8B2', color: '#fff' })},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Variable")])]),_c('div',{staticClass:"text item"},[_c('div',{style:({
              width: '100%',
              whiteSpace: 'break-spaces',
              textAlign: 'left',
            })},_vm._l((_vm.variables),function(item){return _c('div',{key:item,style:({ display: 'inline-block' })},[_c('el-tag',{style:({
                  marginBottom: '10px',
                  marginRight: '10px',
                }),attrs:{"type":"info"}},[_vm._v(_vm._s(item))])],1)}),0)])])],1)]),_c('DesignToolLogic-Arrow-Bottom')],1)}
var staticRenderFns = []

export { render, staticRenderFns }