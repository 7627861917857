<template scoped>
  <div>
    <div :style="{ whiteSpace: 'nowrap' }">
      <div class="designtool-read">
        <div>
          <el-card
            class="box-card"
            :style="{ background: '#666A86', color: '#fff' }"
          >
            <div slot="header" class="clearfix">
              <span>Read Command</span>
            </div>

            <div class="text item">
              {{ description }}
            </div>
          </el-card>
          <DesignToolLogic-Edit
            :id="id"
            :editLogic="editLogic"
          ></DesignToolLogic-Edit>
          <DesignToolLogic-Delete
            :id="id"
            :deleteLogic="deleteLogic"
          ></DesignToolLogic-Delete>
        </div>
      </div>

      <div class="designtool-arrow-right">
        <DesignToolLogic-Arrow-Right> </DesignToolLogic-Arrow-Right>
      </div>

      <div class="designtool-read" :style="{ marginRight: '10px' }">
        <el-card
          class="box-card"
          :style="{ background: '#97D8B2', color: '#fff' }"
        >
          <div slot="header" class="clearfix">
            <span>Variable</span>
          </div>

          <div class="text item">
            <div
              :style="{
                width: '100%',
                whiteSpace: 'break-spaces',
                textAlign: 'left',
              }"
            >
              <div
                v-for="item in variables"
                :key="item"
                :style="{ display: 'inline-block' }"
              >
                <el-tag
                  type="info"
                  :style="{
                    marginBottom: '10px',
                    marginRight: '10px',
                  }"
                  >{{ item }}</el-tag
                >
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <DesignToolLogic-Arrow-Bottom> </DesignToolLogic-Arrow-Bottom>
  </div>
</template>

<script scoped>
export default {
  props: {
    id: String,
    description: String,
    deleteLogic: Function,
    variables: Array,
    editLogic: Function,
  },
  components: {
    "DesignToolLogic-Arrow-Bottom": () => import("./Arrow-Bottom.vue"),
    "DesignToolLogic-Arrow-Right": () => import("./Arrow-Right.vue"),
    "DesignToolLogic-Delete": () => import("./Delete.vue"),
    "DesignToolLogic-Edit": () => import("./Edit.vue"),
  },
};
</script>

<style scoped>
.designtool\-read {
  display: inline-block;
  width: 300px;
  text-align: center;
  position: relative;
  vertical-align: top;
}

.designtool-arrow-right {
  display: inline-block;
  vertical-align: top;
}
</style>

